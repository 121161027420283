import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const IdeasPage = () => {
  return (
    <Layout>
      <Head title="Ideas" />
      <div>
        <h1>Ideas</h1>
        <h2>
          I would either like to break the ideas into categories or create a
          "project" sections for each idea.
        </h2>
      </div>
    </Layout>
  )
}

export default IdeasPage
